import { RemoteConfigContext } from "../../context/dataContext";
import { useContext } from "react";

const Home = () => {
  const remoteConfigContext = useContext(RemoteConfigContext)

  return (
    <div className='w-screen h-screen overflow-y-auto scroll-smooth overflow-x-auto'>
      <div className="min-h-screen flex flex-col px-[20px] py-[20px] sm:px-[80px] md:px-[120px] sm:py-[45px]">
        <div className="flex items-center gap-4">
          <img className="w-[26px] h-[32px] object-contain" src="/icons/ic_budgetify.png" alt="Budgetify Icon" />

          <p className="text-[18px]/[21.78px] font-semibold">Budgetify</p>

          <div className="flex ml-auto gap-12 items-center">
            <a
              href="#features"
              className="text-[14px]/[16.94px] text-[#2C2C2C] hidden sm:block"
            >
              Features
            </a>

            <a
              href={`mailto:${remoteConfigContext?.config['contact_email']}`}
              className="text-[14px]/[16.94px] text-[#2C2C2C] hidden sm:block"
            >
              Contact
            </a>

            <a
              href={remoteConfigContext?.config['app_store_link']}
              target='_blank' rel='noreferrer'
              className="w-[120px] h-[40px] flex items-center bg-[#4772FA] rounded-full px-[26px] py-[10px]"
            >
              <p className="text-[14px]/[16.94px] text-white font-semibold">Download</p>
            </a>
          </div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 w-full my-auto items-center mt-4 sm:mt-[10%]">
          <div className="flex flex-col w-full sm:max-w-[500px] gap-10">
            <div className="flex flex-col gap-2">
              <p className="text-[42px]/[52px] font-semibold">Simple, customizable tracker for your finances.</p>
              <p className="text-[16px]/[26px] text-[#666666]">Budgetify is a simple expense tracker with features like custom icons and widgets, which provides the best expense tracking experience</p>
            </div>

            <a
              href={remoteConfigContext?.config['app_store_link']}
              target='_blank' rel='noreferrer'
              className="w-[160px] h-[56px] text-[16px]/[19.36px] whitespace-nowrap text-white font-semibold bg-[#4772FA] rounded-full px-[36px] py-[18px]"
            >
              Try for free
            </a>
          </div>

          <div className="ml-auto relative px-12 h-[320px] mt-[20%] sm:mt-0">
            <img className="w-full" src="/bg-section-1.png" />

            <img className="absolute top-[5%] left-[10%] h-[250px] sm:h-[450px] object-contain" src="/mockups/mockup-transactions.png" />

            <img className="absolute top-[15%] left-[60%] h-[250px] sm:h-[450px] object-contain" src="/mockups/mockup-transaction-sheet.png" />
          </div>
        </div>
      </div>

      <div id="features" className="sm:min-h-screen w-screen flex bg-[#1E1E1E] p-[20px] sm:pl-[120px] sm:pr-[40px] sm:py-[80px]">
        <div className="grid grid-cols-1 sm:grid-cols-2 my-auto w-full">
          <div className="flex flex-col w-full">
            <div className="flex flex-col gap-2 max-w-[520px]">
              <p className="text-[28px]/[38px] font-semibold text-white">Track, manage, and analyze your expenses with our features</p>
              <p className="text-[16px]/[26px] text-[#FFFFFF99]">Effortless expense tracking through intuitive reports, wallets, budgets, widgets, categories, custom icons, and sharing.</p>
            </div>

            <div className="w-full sm:max-w-[600px] flex items-center h-[350px] sm:h-[550px]">
              <div className="flex w-full relative">
                <div className="absolute w-full h-full top-0 left-0 flex items-center justify-center">
                  <img className="h-[250px] sm:h-[420px]" src="/bg-section-2.png" />
                </div>

                <div className="absolute w-full h-full top-0 left-0 flex items-center justify-center">
                  <img className="h-[300px] sm:h-[450px] object-contain" src="/mockups/mockup-transactions.png" />
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-4 w-full justify-center px-0 sm:px-[80px]">
            <div className="max-w-[464px] flex flex-col gap-2 rounded-[16px] bg-[#2C2C2C] px-[32px] py-[24px] ml-auto">
              <img className="h-[36px] w-[36px]" src="/icons/ic-report.svg" />

              <p className="text-[18px]/[24px] font-semibold text-white">Learn your expense pattern</p>
              <p className="text-[14px]/[22px] text-[#FFFFFF99]">Get a report on what you spend the most on, so you can manage your expenses better</p>
            </div>

            <div className="max-w-[464px] flex flex-col gap-2 rounded-[16px] bg-[#2C2C2C66] px-[32px] py-[24px] ml-auto">
              <img className="h-[36px] w-[36px]" src="/icons/ic-categories.svg" />

              <p className="text-[18px]/[24px] font-semibold text-white">Create custom categories</p>
              <p className="text-[14px]/[22px] text-[#FFFFFF99]">Categorize your transactions by creating custom categories for better expense tracking</p>
            </div>

            <div className="max-w-[464px] flex flex-col gap-2 rounded-[16px] bg-[#2C2C2C33] px-[32px] py-[24px] ml-auto">
              <img className="h-[36px] w-[36px]" src="/icons/ic-phone.svg" />

              <p className="text-[18px]/[24px] font-semibold text-white">Widgets</p>
              <p className="text-[14px]/[22px] text-[#FFFFFF99]">Easily add transactions or check your expenses from your home screen</p>
            </div>

            <div className="max-w-[464px] flex flex-col gap-2 rounded-[16px] px-[32px] py-[24px] ml-auto">
              <img className="h-[36px] w-[36px]" src="/icons/ic-wrench.svg" />

              <p className="text-[18px]/[24px] font-semibold text-white">Customizable icons</p>
              <p className="text-[14px]/[22px] text-[#FFFFFF99]">Make the app more personal by using custom icons for categories, wallets, and budgets</p>
            </div>
          </div>
        </div>
      </div>

      <div className="relative h-fit flex flex-col items-center justify-center">
        <img className="absolute w-full h-full object-left object-cover" src="/bg-section-3.png" />

        <div className="flex flex-col z-[1] max-w-[540px] mx-auto gap-4 sm:gap-8 m-[20px] sm:m-[120px] px-[20px]">
          <div className="flex flex-col gap-2">
            <p className="text-[16px]/[26px] sm:text-[28px]/[38px] font-semibold text-white text-center">Get a better tracking & budgeting experience with Budgetify!</p>
          </div>

          <a
            href={remoteConfigContext?.config['app_store_link']}
            target='_blank' rel='noreferrer'
            className="flex items-center justify-center  bg-[#4772FA] rounded-full mx-auto py-[18px] px-[52px]"
          >
            <p className="text-[16px]/[19.36px] whitespace-nowrap text-white font-semibold">Download Now</p>

          </a>
        </div>
      </div>

      <div className="block sm:flex py-3 sm:py-8 px-5 sm:px-16">
        <p className="text-[14px]/[26px] text-[#999999] sm:block hidden">© 2023 Joses Solmaximo. All rights reserved.</p>

        <div className="grid grid-cols-3 gap-1 sm:gap-10 ml-auto">
          <a className="text-[12px] text-center sm:text-left sm:text-[14px]/[26px] cursor-pointer" href={remoteConfigContext?.config['privacy_policy_link']} target='_blank' rel='noreferrer'>Privacy Policy</a>
          <a className="text-[12px] text-center sm:text-left sm:text-[14px]/[26px] cursor-pointer" href={remoteConfigContext?.config['terms_of_use_link']} target='_blank' rel='noreferrer'>Terms of Service</a>
          <a className="text-[12px] text-center sm:text-left sm:text-[14px]/[26px] cursor-pointer" href={`mailto:${remoteConfigContext?.config['contact_email']}`}>Contact</a>
        </div>

        <p className="text-[12px]/[12px] mt-2 sm:mt-0 text-center sm:text-left text-[#999999] sm:hidden block">© 2023 Joses Solmaximo. All rights reserved.</p>
      </div>
    </div>
  );
}

export default Home;