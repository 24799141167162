import { Route, Routes } from "react-router-dom"
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useCookies } from 'react-cookie';
import { useEffect } from "react";
import ProtectedRoute from "./routes/protectedRoute";

import Home from './pages/home/home';
import Admin from './pages/admin/admin';
import AdminLogin from './pages/adminLogin/adminLogin';
import Users from "./pages/users/users";
import Dashboard from "./pages/dashboard/dashboard";
import PrivacyPolicy from "./pages/privacyPolicy/privacyPolicy";
import DataContext from "./context/dataContext";

function App() {
    const [cookies, setCookie, removeCookie] = useCookies(['uid']);

    const auth = getAuth()

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setCookie('uid', user.uid, { path: '/' })
            } else {
                removeCookie('uid', { path: '/' })
            }
        });
    }, [])

    return (
        <div className="overscroll-contain">
            <Routes>
                {/* <Route path="/admin/login" element={<AdminLogin />} />

                <Route path="/admin" element={
                    <ProtectedRoute hasAccess={cookies.uid !== undefined} redirect='/admin/login'>
                        <Admin />
                    </ProtectedRoute>
                } >
                    <Route path="dashboard" element={<Dashboard/>} />
                    <Route path="users" element={<Users/>} />
                    <Route path="roadmap" element={<p>roadmap</p>} />
                </Route> */}

                <Route path="*" element={
                    <DataContext>
                        <Home />
                    </DataContext>
                } />
                <Route path="/privacypolicy" element={
                    <DataContext>
                        <PrivacyPolicy />
                    </DataContext>
                } />
            </Routes>
        </div>
    );
}

export default App;
